export const NOT_NULL = 'not_null';
export const OPTIONAL = 'optional';

export const AccessLevels = {
  All: 0,
  Employee: 1,
  Instructor: 2,
  Admin: 3,
  Superuser: 4,
};
