import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {
  NEWS_CLEAR_ACTIVE,
  NEWS_GET_ALL_FAILURE,
  NEWS_GET_ALL_REQUEST,
  NEWS_GET_ALL_SUCCESS,
  NEWS_GET_FAILURE,
  NEWS_GET_REQUEST,
  NEWS_GET_SUCCESS,
  NEWS_SET_ACTIVE,
} from '@actions/news.actions';

const initialState = {
  list: {
    isFetching: false,
    data: null,
    error: null,
  },
  fetched: {
    isFetching: false,
    data: [],
    error: null,
  },
  active: null,
};

const news = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case NEWS_GET_ALL_REQUEST: {
    return {
      ...state,
      list: {
        ...state.list,
        isFetching: true,
      },
    };
  }
  case NEWS_GET_ALL_SUCCESS: {
    return {
      ...state,
      list: {
        ...state.list,
        isFetching: false,
        data: action.payload.news,
        error: null,
      },
    };
  }
  case NEWS_GET_ALL_FAILURE: {
    return {
      ...state,
      list: {
        ...state.list,
        isFetching: false,
        error: action.payload.error,
      },
    };
  }
  case NEWS_GET_REQUEST: {
    return {
      ...state,
      fetched: {
        ...state.fetched,
        isFetching: true,
      },
    };
  }
  case NEWS_GET_SUCCESS: {
    return {
      ...state,
      fetched: {
        ...state.fetched,
        data: [...state.fetched.data, action.payload.news],
        isFetching: false,
        error: null,
      },
    };
  }
  case NEWS_GET_FAILURE: {
    return {
      ...state,
      fetched: {
        ...state.fetched,
        isFetching: false,
        error: action.payload.error,
      },
    };
  }
  case NEWS_SET_ACTIVE: {
    return {
      ...state,
      active: action.payload.id,
    };
  }
  case NEWS_CLEAR_ACTIVE: {
    return {
      ...state,
      active: null,
    };
  }
  default: {
    return state;
  }
  }
};

export default news;
