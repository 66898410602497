export const THEME_SET_CSS_VARS = 'theme/setCssVars';
export const THEME_SET_CUSTOM_THEME = 'theme/setCustomTheme';
export const THEME_SET_THEME_BY_KEY = 'theme/setThemeByKey';

export const themeSetCSSVars = payload => ({
  type: THEME_SET_CSS_VARS,
  payload,
});

export const themeSetCustomTheme = payload => ({
  type: THEME_SET_CUSTOM_THEME,
  payload,
});

export const themeSetThemeByKey = payload => ({
  type: THEME_SET_THEME_BY_KEY,
  payload,
});
