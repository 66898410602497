const prefix = '[HELP]';

export const HELP_GET_DATA = `${prefix} HELP_GET_DATA`;
export const HELP_GET_DATA_REQUEST = `${prefix} HELP_GET_DATA_REQUEST`;
export const HELP_GET_DATA_SUCCESS = `${prefix} HELP_GET_DATA_SUCCESS`;
export const HELP_GET_DATA_FAILURE = `${prefix} HELP_GET_DATA_FAILURE`;

export const helpGetData = payload => ({
  payload,
  type: HELP_GET_DATA,
});

export const helpGetDataRequest = () => ({type: HELP_GET_DATA_REQUEST});

export const helpGetDataSuccess = payload => ({
  type: HELP_GET_DATA_SUCCESS,
  payload,
});

export const helpGetDataFailure = payload => ({
  type: HELP_GET_DATA_FAILURE,
  payload,
});

export const HELP_SEARCH = 'HELP_SEARCH';
export const HELP_SEARCH_REQUEST = `${prefix} HELP_SEARCH_REQUEST`;
export const HELP_SEARCH_SUCCESS = `${prefix} HELP_SEARCH_SUCCESS`;
export const HELP_SEARCH_FAILURE = `${prefix} HELP_SEARCH_FAILURE`;

export const helpSearch = payload => ({
  payload,
  type: HELP_SEARCH,
});

export const helpSearchRequest = () => ({type: HELP_SEARCH_REQUEST});

export const helpSearchSuccess = payload => ({
  type: HELP_SEARCH_SUCCESS,
  payload,
});

export const helpSearchFailure = payload => ({
  type: HELP_SEARCH_FAILURE,
  payload,
});

export const HELP_SEND_QUESTION = 'HELP_SEND_QUESTION';
export const HELP_SEND_QUESTION_REQUEST = `${prefix} HELP_SEND_QUESTION_REQUEST`;
export const HELP_SEND_QUESTION_SUCCESS = `${prefix} HELP_SEND_QUESTION_SUCCESS`;
export const HELP_SEND_QUESTION_FAILURE = `${prefix} HELP_SEND_QUESTION_FAILURE`;

export const helpSendQuestion = payload => ({
  payload,
  type: HELP_SEND_QUESTION,
});

export const helpSendQuestionRequest = () => ({type: HELP_SEND_QUESTION_REQUEST});

export const helpSendQuestionSuccess = payload => ({
  type: HELP_SEND_QUESTION_SUCCESS,
  payload,
});

export const helpSendQuestionFailure = payload => ({
  type: HELP_SEND_QUESTION_FAILURE,
  payload,
});

export const HELP_SEND_QUESTION_RESET = 'HELP_SEND_QUESTION_RESET';

export const helpSendQuestionReset = () => ({type: HELP_SEND_QUESTION_RESET});
