const exportObj = {
  'color-main-dark': '#003050',
  'color-main-dark-2': '#002c49',
  'color-main-light': '#618da7',
  'color-main': '#003f69',
  'color-header-top': '#003f69',
  'color-header-bottom': '#fff',
  'color-header-text': '#000',
  'color-header-link-underline': '#ef5921',
  'color-link': '#000',
  'color-input-text': '#cfcfbf',
  'color-action': '#003f69',
};

export default exportObj;
