import {darken} from 'polished';
import {isDark} from '@snapper/core';
import {baseTheme, baseThemeColors} from '../base';

export const createButtonsTheme = ({
  primary = baseThemeColors.primary,
  surface = baseThemeColors.surface,
  scheme = {}, // baseTheme.scheme.primary,
  buttons = {}, // baseTheme.buttons,
  checkContrast = false,
}) => {
  const _buttons = {
    ...baseTheme.buttons,
    ...buttons,
    primaryTheme: {
      ...baseTheme.buttons.primaryTheme,
      backgroundColor: '#444444',
      backgroundColorHover: darken(0.1, primary),
      ...buttons?.primaryTheme,
    },
    oversize: {
      ...baseTheme.buttons.oversize,
      backgroundColor: primary,
      ...buttons?.oversize,
    },
    defaultTheme: {
      ...baseTheme.buttons.defaultTheme,
      color: primary,
      // colorHover: darken(0.1, primary),
      border: '1px solid ' + primary,
      borderHover: '1px solid ' + darken(0.1, primary),
      ...buttons?.defaultTheme,
    },
    primary: {
      ...baseTheme.buttons.primary,
      ...buttons?.primary,
    },
    toggle: {
      ...baseTheme.buttons.toggle,
      // color: '#000',
      colorSelected: primary,
      backgroundColorSelected: surface,
      borderColorSelected: primary,
      ...buttons.toggle,
    },
  };

  if (!checkContrast) return _buttons;

  // check contrast for bg and text
  if (isDark(buttons.defaultTheme.backgroundColor) === isDark(buttons.defaultTheme.color)) {
    buttons.defaultTheme.color = isDark(buttons.defaultTheme.backgroundColor)
      ? '#fff'
      : '#000';
  }

  if (isDark(buttons.defaultTheme.backgroundColorHover) === isDark(buttons.defaultTheme.colorHover)) {
    buttons.defaultTheme.colorHover = isDark(buttons.defaultTheme.backgroundColorHover)
      ? '#fff'
      : '#000';
  }

  if (isDark(buttons.toggle.color) === isDark(buttons.toggle.backgroundColorSelected)) {
    buttons.toggle.backgroundColorSelected = isDark(buttons.toggle.color)
      ? '#fff'
      : '#000';
  }

  if (isDark(buttons.toggle.color) === isDark(buttons.toggle.colorSelected)) {
    buttons.toggle.colorSelected = isDark(buttons.toggle.color)
      ? '#fff'
      : '#000';
  }

  if (isDark(buttons.oversize.backgroundColor) === isDark(buttons.oversize.color)) {
    buttons.oversize.color = isDark(buttons.oversize.backgroundColor)
      ? '#fff'
      : '#000';
  }

  if (isDark(buttons.primaryTheme.backgroundColor) === isDark(buttons.primaryTheme.color)) {
    buttons.primaryTheme.color = isDark(buttons.primaryTheme.backgroundColor)
      ? '#fff'
      : '#000';
  }

  if (isDark(buttons.primaryTheme.backgroundColorHover) === isDark(buttons.primaryTheme.colorHover)) {
    buttons.primaryTheme.colorHover = isDark(buttons.primaryTheme.backgroundColorHover)
      ? '#fff'
      : '#000';
  }

  return _buttons;
};
