import {LoadingLine} from '@components/loading/loading-line';
import {loadableRetry} from '@utils/loadable-retry';

const LoadableReportSwitch = loadableRetry(() => {
  console.log('load report.switch');

  return import(/* webpackChunkName: "report.switch" */ './report-switch');
}, {
  fallback: <LoadingLine />,
  name: 'report.switch',
});

export default LoadableReportSwitch;
