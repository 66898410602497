import React from 'react';
import {LoadingLine} from '@components/loading/loading-line';
import {loadableRetry} from '@utils/loadable-retry';

const LoadableAdminSwitch = loadableRetry(() => {
  console.log('load admin.switch');

  return import(/* webpackChunkName: "admin.switch" */ './admin-switch');
}, {
  fallback: <LoadingLine />,
  name: 'admin.switch',
});

export default LoadableAdminSwitch;
