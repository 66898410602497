const Number = 'number';
const String = 'string';
const Object = 'object';
const Array = 'array';
const Boolean = 'boolean';

export const PayloadTypes = {
  orgId: {
    key: 'orgId',
    type: Number,
  },
  personId: {
    key: 'personId',
    type: Number,
  },
  userName: {
    key: 'userName',
    type: String,
  },
  fullname: {
    key: 'fullname',
    type: String,
  },
  competence: {
    key: 'competence',
    type: Object,
  },
  person: {
    key: 'person',
    type: Object,
  },
  competenceId: {
    key: 'competenceId',
    type: Number,
  },
  phceId: {
    key: 'phceId',
    type: Number,
  },
  courseId: {
    key: 'courseId',
    type: Number,
  },
  eventId: {
    key: 'eventId',
    type: Number,
  },
  roleId: {
    key: 'roleId',
    type: Number,
  },
  trackId: {
    key: 'trackId',
    type: Number,
  },
  mapId: {
    key: 'mapId',
    type: Number,
  },
  defaultData: {
    key: 'defaultData',
    type: Object,
  },
  props: {
    key: 'props',
    type: Object,
  },
};
