import React, {useRef} from 'react';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import {
  ActionButtonsWrapper,
  Base,
  box,
  Button,
  ButtonLink,
  Input,
  Label,
  mt,
  stack,
} from './styles';

const ChangePasswordForm = ({
  handleSubmit,
  handleCancel,
  forgotStatus,
  forgotStatusMsg,
}) => {
  const ref = useRef(null);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(ref.current.value);
      }}
      css={[
        box,
        stack,
        css`width: 100%;`,
      ]}
    >
      <div css={css`width: 100%;`}>
        <Label htmlFor="username-field">{i18n('login.username')}</Label>
        <Base sizeVariant="fullwidth">
          <Input
            id="username-field"
            autoComplete="username"
            name="username"
            type="text"
            placeholder={i18n('login.username-placeholder')}
            isRequired={true}
            ref={ref}
          />
        </Base>
        {forgotStatus === 1 && (
          <div
            role="alert"
            css={css`
            color: #ee0c00;
            margin-bottom: 1em;
          `}
          >
            {i18n('login.no-user')}
            <br />
            {/* i18n('NO_USER_FOUND_HELP_EMAIL') */}
          </div>
        )
        || forgotStatus !== -1 && <div>{forgotStatusMsg}</div>}
      </div>

      <ActionButtonsWrapper>
        <Button
          sizeVariant="fullwidth"
          type="submit"
        >
          {i18n('login.recive-new')}
        </Button>
        <ButtonLink
          css={[
            mt,
            css`
            width: 100%;
          `,
          ]}
          type="button"
          onClick={handleCancel}
        >
          {i18n('globals.cancel')}
        </ButtonLink>
      </ActionButtonsWrapper>
    </form>
  );
};

export default ChangePasswordForm;
