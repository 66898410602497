const prefix = 'MESSAGES';

export const MESSAGE_SET_READ = 'MESSAGE_SET_READ';
export const MESSAGE_SET_READ_REQUEST = 'MESSAGE_SET_READ_REQUEST';
export const MESSAGE_SET_READ_SUCCESS = 'MESSAGE_SET_READ_SUCCESS';
export const MESSAGE_SET_READ_FAILURE = 'MESSAGE_SET_READ_FAILURE';

export const messageSetRead = payload => ({
  type: MESSAGE_SET_READ,
  payload,
});

export const messageSetReadRequest = () => ({type: MESSAGE_SET_READ_REQUEST});

export const messageSetReadSuccess = payload => ({
  type: MESSAGE_SET_READ_SUCCESS,
  payload,
});

export const messageSetReadFailure = payload => ({
  type: MESSAGE_SET_READ_FAILURE,
  payload,
});

export const MESSAGES_GET_MESSAGES = `${prefix} GET MESSAGES`;
export const MESSAGES_GET_REQUEST = `${prefix} GET REQUEST`;
export const MESSAGES_GET_SUCCESS = `${prefix} GET SUCCESS`;
export const MESSAGES_GET_FAILURE = `${prefix} GET FAILURE`;

export const messagesGetMessages = payload => ({
  type: MESSAGES_GET_MESSAGES,
  payload,
});

export const messagesGetRequest = payload => ({
  type: MESSAGES_GET_REQUEST,
  payload,
});

export const messagesGetSuccess = payload => ({
  type: MESSAGES_GET_SUCCESS,
  payload,
});

export const messagesGetFailure = payload => ({
  type: MESSAGES_GET_FAILURE,
  payload,
});

export const MESSAGES_CHECK_MESSAGES = 'MESSAGES_CHECK_MESSAGES';
export const MESSAGES_CHECK_MESSAGES_REQUEST = 'MESSAGES_CHECK_MESSAGES_REQUEST';
export const MESSAGES_CHECK_MESSAGES_SUCCESS = 'MESSAGES_CHECK_MESSAGES_SUCCESS';
export const MESSAGES_CHECK_MESSAGES_FAILURE = 'MESSAGES_CHECK_MESSAGES_FAILURE';

export const messagesCheckMessages = () => ({type: MESSAGES_CHECK_MESSAGES});

export const messagesCheckMessagesRequest = () => ({type: MESSAGES_CHECK_MESSAGES_REQUEST});

export const messagesCheckMessagesSuccess = payload => ({
  type: MESSAGES_CHECK_MESSAGES_SUCCESS,
  payload,
});

export const messagesCheckMessagesFailure = payload => ({
  type: MESSAGES_CHECK_MESSAGES_FAILURE,
  payload,
});

export const MESSAGES_SEND_MESSAGE = `${prefix} SEND MESSAGE`;
export const MESSAGES_SEND_MESSAGE_POST_REQUEST = `${prefix} SEND MESSAGE POST REQUEST`;
export const MESSAGES_SEND_MESSAGE_POST_SUCCESS = `${prefix} SEND MESSAGE POST SUCCESS`;
export const MESSAGES_SEND_MESSAGE_POST_FAILURE = `${prefix} SEND MESSAGE POST FAILURE`;

export const MESSAGES_SET_READ_SUCCESS = `${prefix} SET MESSAGE READ SUCCESS`;

export const messagesSendMessage = payload => ({
  type: MESSAGES_SEND_MESSAGE,
  payload,
});

export const messagesSendMessagePostRequest = () => ({type: MESSAGES_SEND_MESSAGE_POST_REQUEST});

export const messagesSendMessagePostSuccess = payload => ({
  type: MESSAGES_SEND_MESSAGE_POST_SUCCESS,
  payload,
});

export const messagesSendMessagePostFailure = payload => ({
  type: MESSAGES_SEND_MESSAGE_POST_FAILURE,
  payload,
});

export const messagesSetReadSuccess = payload => ({
  type: MESSAGES_SET_READ_SUCCESS,
  payload,
});
