// a modified version of
// https://stackoverflow.com/a/75732952
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0

import loadable from '@loadable/component';

const isDev = process.env.NODE_ENV === 'development';

export const loadableRetry = (componentImport, args) => {
  const {name = '', fallback} = args;

  return loadable(async () => {
    const key = 'page-has-been-force-refreshed-' + name;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(key) || 'false');

    try {
      const component = await componentImport();

      if (isDev && window.localStorage.getItem(key) === 'true') {
        // eslint-disable-next-line no-console
        console.log(`%c${name} has already been registered`, 'color: #ff0000');
      }

      window.localStorage.setItem(
        key,
        'false',
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
      // Assuming that the user is not on the latest version of the application.
      // Let's clear the cache and reload the page.
        window.localStorage.setItem(
          key,
          'true',
        );
        if ('caches' in window) {
          await caches.keys().then(names => {
            names.forEach(name => {
              caches.delete(name);
            });
          });
        }
        localStorage.removeItem('learningportalConfig');

        return window.location.reload(true);
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  }, fallback ? {fallback} : undefined);
};
