const prefix = 'competences';

export const COMPETENCES_TOGGLE = `${prefix}/TOGGLE`;
export const COMPETENCES_TOGGLE_REQUEST = `${prefix}/TOGGLE/REQUEST`;
export const COMPETENCES_TOGGLE_SUCCESS = `${prefix}/TOGGLE/SUCCESS`;
export const COMPETENCES_TOGGLE_FAILURE = `${prefix}/TOGGLE/FAILURE`;

export const competencesToggle = payload => ({
  type: COMPETENCES_TOGGLE,
  payload,
});

export const competencesToggleRequest = payload => ({
  type: COMPETENCES_TOGGLE_REQUEST,
  payload,
});

export const competencesToggleSuccess = payload => ({
  type: COMPETENCES_TOGGLE_SUCCESS,
  payload,
});

export const competencesToggleFailure = payload => ({
  type: COMPETENCES_TOGGLE_FAILURE,
  payload,
});

export const COMPETENCES_SAVE = 'COMPETENCES_SAVE';
export const COMPETENCES_SAVE_REQUEST = 'COMPETENCES_SAVE_REQUEST';
export const COMPETENCES_SAVE_SUCCESS = 'COMPETENCES_SAVE_SUCCESS';
export const COMPETENCES_SAVE_FAILURE = 'COMPETENCES_SAVE_FAILURE';

export const competenceSave = payload => ({
  type: COMPETENCES_SAVE,
  payload,
});

export const competenceSaveRequest = payload => ({
  type: COMPETENCES_SAVE_REQUEST,
  payload,
});

export const competenceSaveSuccess = payload => ({
  type: COMPETENCES_SAVE_SUCCESS,
  payload,
});

export const competenceSaveFailure = payload => ({
  type: COMPETENCES_SAVE_FAILURE,
  payload,
});

export const COMPETENCES_REMOVE = 'COMPETENCES_REMOVE';
export const COMPETENCES_REMOVE_REQUEST = 'COMPETENCES_REMOVE_REQUEST';
export const COMPETENCES_REMOVE_SUCCESS = 'COMPETENCES_REMOVE_SUCCESS';
export const COMPETENCES_REMOVE_FAILURE = 'COMPETENCES_REMOVE_FAILURE';

export const competenceRemove = payload => ({
  type: COMPETENCES_REMOVE,
  payload,
});

export const competenceRemoveRequest = payload => ({
  type: COMPETENCES_REMOVE_REQUEST,
  payload,
});

export const competenceRemoveSuccess = payload => ({
  type: COMPETENCES_REMOVE_SUCCESS,
  payload,
});

export const competenceRemoveFailure = payload => ({
  type: COMPETENCES_REMOVE_FAILURE,
  payload,
});
