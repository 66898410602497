import {isDark} from '@snapper/core';
import {baseTheme} from '../base';

export const createCourseCatalogTheme = ({
  scheme = {},
  courseCatalog = {},
  checkContrast = false,
}) => {
  const coursegroups = {
    ...courseCatalog?.coursegroups,
    buttons: {
      ...courseCatalog?.coursegroups?.buttons,
      inactive: {
        backgroundColor: scheme.color50,
        ...courseCatalog?.coursegroups?.buttons?.inactive,
      },
      active: {
        backgroundColor: scheme.color700,
        ...courseCatalog?.coursegroups?.buttons?.active,
      },
      hover: {
        backgroundColor: scheme.color100,
        ...courseCatalog?.coursegroups?.buttons?.hover,
      },
    },
  };

  const toggleSwitch = {
    ...courseCatalog?.toggleSwitch,
    inactive: {
      backgroundColor: 'transparent',
      ...courseCatalog?.toggleSwitch?.inactive,
    },
    active: {
      backgroundColor: scheme.color25,
      color: scheme.color900,
      ...courseCatalog?.toggleSwitch?.active,
    },
    hover: {
      backgroundColor: 'transparent',
      ...courseCatalog?.toggleSwitch?.hover,
    },
  };

  // check contrast for bg and text
  if (checkContrast) {
    if (isDark(coursegroups.buttons.active.backgroundColor) === isDark(coursegroups.buttons.active.color)) {
      coursegroups.buttons.active.color = isDark(coursegroups.buttons.active.backgroundColor)
        ? '#fff'
        : '#000';
    }

    if (isDark(coursegroups.buttons.inactive.backgroundColor) === isDark(coursegroups.buttons.inactive.color)) {
      coursegroups.buttons.inactive.color = isDark(coursegroups.buttons.inactive.backgroundColor)
        ? '#fff'
        : '#000';
    }

    if (isDark(coursegroups.buttons.hover.backgroundColor) === isDark(coursegroups.buttons.hover.color)) {
      coursegroups.buttons.hover.color = isDark(coursegroups.buttons.hover.backgroundColor)
        ? '#fff'
        : '#000';
    }

    if (isDark(toggleSwitch.active.backgroundColor) === isDark(toggleSwitch.active.color)) {
      toggleSwitch.active.color = isDark(toggleSwitch.active.backgroundColor)
        ? '#fff'
        : '#000';
    }

    if (isDark(toggleSwitch.inactive.backgroundColor) === isDark(toggleSwitch.inactive.color)) {
      toggleSwitch.inactive.color = isDark(toggleSwitch.inactive.backgroundColor)
        ? '#fff'
        : '#000';
    }

    if (isDark(toggleSwitch.hover.backgroundColor) === isDark(toggleSwitch.hover.color)) {
      toggleSwitch.hover.color = isDark(toggleSwitch.hover.backgroundColor)
        ? '#fff'
        : '#000';
    }
  }

  return {
    ...baseTheme.courseCatalog,
    ...courseCatalog,
    coursegroups,
    toggleSwitch,
  };
};
