import * as React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
	color: ${props => props?.color || props.theme.scheme.primary.color500 || 'cadetblue'};
	display: inline-block;
	text-align: initial;
  position: relative;

	.loading-spinner-wrapper {
		height: ${props => props.size}px;
		margin: auto;
		overflow: hidden;
		position: relative;
		width: ${props => props.size}px;
    min-height: ${props => props.size}px;
    min-width: ${props => props.size}px;
	}

  .spinner-children-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

	.loading-spinner-bg,
	.loading-spinner-bg-blur {
		height: 100%;
		position: absolute;
		stroke: ${props => props?.color || props.theme.scheme.primary.color500 || 'cadetblue'};
		top: 0;
		width: 100%;
	}
	.loading-spinner-bg-stroke {
		stroke: #ededfa;
	}
	.loading-spinner-bg-blur {
		filter: blur(${props => props.size / 10}px);
		opacity: 0.1;
	}
	.loading-spinner-slice1,
	.loading-spinner-slice2,
	.loading-spinner-slice3,
	.loading-spinner-slice4,
	.loading-spinner-slice5 {
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
		height: 50%;
		left: 50%;
		position: absolute;
		top: 0;
		transform-origin: left bottom;
		width: 50%;
	}
	.loading-spinner-slice1 {
		animation-name: slicespin1;
		transform: rotate(54deg);
	}
	.loading-spinner-slice2 {
		animation-name: slicespin2;
		transform: rotate(124deg);
	}
	.loading-spinner-slice3 {
		animation-name: slicespin3;
		transform: rotate(198deg);
	}
	.loading-spinner-slice4 {
		animation-name: slicespin4;
		transform: rotate(270deg);
	}
	.loading-spinner-slice5 {
		animation-name: slicespin5;
		transform: rotate(344deg);
	}
	@keyframes slicespin1 {
		0% { transform: rotate(54deg); }
		80%, 100% { transform: rotate(430deg); }
	}
	@keyframes slicespin2 {
		0%, 10% { transform: rotate(124deg); }
		80%, 100% { transform: rotate(500deg); }
	}
	@keyframes slicespin3 {
		0%, 20% { transform: rotate(198deg); }
		80%, 100% { transform: rotate(574deg); }
	}
	@keyframes slicespin4 {
		0%, 35% { transform: rotate(270deg); }
		80%, 100% { transform: rotate(644deg); }
	}
	@keyframes slicespin5 {
		80%, 100% { transform: rotate(720deg); }
	}
`;

const Element = React.memo(({
  backgroundColor = '#ffffff',
  trackColor = '#E6EAF0',
}) => (
  <div className="loading-spinner-wrapper">
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-bg-blur"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
        transform="translate(5 5)"
      >
        <circle
          stroke="none"
          fill="#000"
          cx="16"
          cy="16"
          r="14"
        />
      </g>
    </svg>
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-bg"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
        transform="translate(5 5)"
      >
        <circle
          strokeWidth="0.5"
          cx="16"
          cy="16"
          r="16"
          className="loading-spinner-bg-stroke"
        />
        <circle
          stroke="none"
          fill={backgroundColor}
          cx="16"
          cy="16"
          r="16"
        />
        <circle
          strokeWidth="2"
          cx="16"
          cy="16"
          r="11"
        />
      </g>
    </svg>
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-slice1"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M24 42h8c0-17.673-14.327-32-32-32v8c1.105 0 2 .895 2 2s-.895 2-2 2v20h20c0-1.105.895-2 2-2s2 .895 2 2z"
          fill={backgroundColor}
        />
        <path
          d="M0 22c1.105 0 2-.895 2-2s-.895-2-2-2c13.255 0 24 10.745 24 24 0-1.105-.895-2-2-2s-2 .895-2 2c0-11.046-8.954-20-20-20z"
          fill={trackColor}
        />
      </g>
    </svg>
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-slice2"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M24 42h8c0-17.673-14.327-32-32-32v8c1.105 0 2 .895 2 2s-.895 2-2 2v20h20c0-1.105.895-2 2-2s2 .895 2 2z"
          fill={backgroundColor}
        />
        <path
          d="M0 22c1.105 0 2-.895 2-2s-.895-2-2-2c13.255 0 24 10.745 24 24 0-1.105-.895-2-2-2s-2 .895-2 2c0-11.046-8.954-20-20-20z"
          fill={trackColor}
        />
      </g>
    </svg>
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-slice3"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M24 42h8c0-17.673-14.327-32-32-32v8c1.105 0 2 .895 2 2s-.895 2-2 2v20h20c0-1.105.895-2 2-2s2 .895 2 2z"
          fill={backgroundColor}
        />
        <path
          d="M0 22c1.105 0 2-.895 2-2s-.895-2-2-2c13.255 0 24 10.745 24 24 0-1.105-.895-2-2-2s-2 .895-2 2c0-11.046-8.954-20-20-20z"
          fill={trackColor}
        />
      </g>
    </svg>
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-slice4"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M24 42h8c0-17.673-14.327-32-32-32v8c1.105 0 2 .895 2 2s-.895 2-2 2v20h20c0-1.105.895-2 2-2s2 .895 2 2z"
          fill={backgroundColor}
        />
        <path
          d="M0 22c1.105 0 2-.895 2-2s-.895-2-2-2c13.255 0 24 10.745 24 24 0-1.105-.895-2-2-2s-2 .895-2 2c0-11.046-8.954-20-20-20z"
          fill={trackColor}
        />
      </g>
    </svg>
    <svg
      viewBox="0 0 42 42"
      className="loading-spinner-slice5"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M24 42h8c0-17.673-14.327-32-32-32v8c1.105 0 2 .895 2 2s-.895 2-2 2v20h20c0-1.105.895-2 2-2s2 .895 2 2z"
          fill={backgroundColor}
        />
        <path
          d="M0 22c1.105 0 2-.895 2-2s-.895-2-2-2c13.255 0 24 10.745 24 24 0-1.105-.895-2-2-2s-2 .895-2 2c0-11.046-8.954-20-20-20z"
          fill={trackColor}
        />
      </g>
    </svg>
  </div>
));

export const FluidSpinner = React.memo(({
  color,
  size = 50,
  backgroundColor = '#ffffff',
  trackColor = '#E6EAF0',
  children,
}) => (
  <Wrapper
    color={color}
    size={size}
  >
    <Element
      backgroundColor={backgroundColor}
      trackColor={trackColor}
    />
    {!!children && <div className="spinner-children-wrapper">{children}</div>}
  </Wrapper>
));
