export const LoadStatuses = {
  NOT_LOADED: 'NOT_LOADED',
  IS_LOADING: 'IS_LOADING',
  IS_LOADING_MORE: 'IS_LOADING_MORE',
  NEED_RELOAD: 'NEED_RELOAD',
  LOADED_PARTIAL: 'LOADED_PARTIAL',
  LOADED: 'LOADED',
  SENDT: 'SENDT',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
  FAILED: 'FAILED',
  NOT_VERIFIED: 'NOT_VERIFIED',
  IDLE: 'IDLE',
  SHOULD_LOAD: 'SHOULD_LOAD',
  INITIAL: 'INITIAL',
};
