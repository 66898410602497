import {MODALS_PUT_MODAL} from '../store/actions';

/**
 * Helper function to create open/close action creators for a specific modal id.
 *
 * @param {string} modalId - The modal id to create action creators for.
 *
 * @returns {object} - The action creators for the modal id.
 * @example
 * const {
 *  open: openCourseBuilderModal,
 *  close: closeCourseBuilderModal
 * } = createModalActionCreators(ModalIds.COURSE_BUILDER);
*/
export function createModalActionCreators(modalId) {
  return {
    open: payload => ({
      type: MODALS_PUT_MODAL,
      payload: {
        modalId,
        isOpen: true,
        payload,
      },
    }),
    close: () => ({
      type: MODALS_PUT_MODAL,
      payload: {
        modalId,
        isOpen: false,
      },
    }),
  };
}
