import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import './notification.scss';

const Notification = ({
  title,
  children,
  onClose,
  disableAutoClose,
  color,
  className,
  redirectTo,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const closeTimeout = useRef(null);

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    });

    if (!disableAutoClose) {
      closeTimeout.current = setTimeout(() => {
        handleClose();
      }, 5300);
    }

    return () => clearTimeout(closeTimeout);
  }, []);

  return (
    <div
      className={classNames([
        {notification: true},
        {[`notification--${color}`]: !!color},
        {[className]: !!className},
        {'notification--visible': visible},
      ])}
      {...rest}
    >
      <div className="notification__content">
        {title && <div className="notification__title">{title}</div>}
        <div className="notification__text">{children}</div>
      </div>
      <button
        type="button"
        className="notification__close-button"
        onClick={handleClose}
      >
        <i className="fas fa-times" />
      </button>
      {redirectTo && (
        <Redirect
          from="*"
          to={redirectTo}
        />
      )}
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disableAutoClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  redirectTo: PropTypes.string,
};

Notification.defaultProps = {
  className: null,
  color: null,
  disableAutoClose: false,
  title: null,
  redirectTo: null,
};

export default Notification;
