export const MANAGE_ROLES_GET = 'MANAGE_ROLES_GET';
export const MANAGE_ROLES_GET_REQUEST = 'MANAGE_ROLES_GET_REQUEST';
export const MANAGE_ROLES_GET_SUCCESS = 'MANAGE_ROLES_GET_SUCCESS';
export const MANAGE_ROLES_GET_FAILURE = 'MANAGE_ROLES_GET_FAILURE';

export const fetchManageRoles = payload => ({
  type: MANAGE_ROLES_GET,
  payload,
});

export const fetchManageRolesRequest = () => ({type: MANAGE_ROLES_GET_REQUEST});

export const fetchManageRolesSuccess = payload => ({
  type: MANAGE_ROLES_GET_SUCCESS,
  payload,
});

export const fetchManageRolesFailure = payload => ({
  type: MANAGE_ROLES_GET_FAILURE,
  payload,
});

export const MANAGE_COMPETENCES_GET = 'MANAGE_COMPETENCES_GET';
export const MANAGE_COMPETENCES_GET_REQUEST = 'MANAGE_COMPETENCES_GET_REQUEST';
export const MANAGE_COMPETENCES_GET_SUCCESS = 'MANAGE_COMPETENCES_GET_SUCCESS';
export const MANAGE_COMPETENCES_GET_FAILURE = 'MANAGE_COMPETENCES_GET_FAILURE';

export const fetchManageCompetences = payload => ({
  type: MANAGE_COMPETENCES_GET,
  payload,
});

export const fetchManageCompetencesRequest = payload => ({
  type: MANAGE_COMPETENCES_GET_REQUEST,
  payload,
});

export const fetchManageCompetencesSuccess = payload => ({
  type: MANAGE_COMPETENCES_GET_SUCCESS,
  payload,
});

export const fetchManageCompetencesFailure = payload => ({
  type: MANAGE_COMPETENCES_GET_FAILURE,
  payload,
});

export const MANAGE_COMPETENCE_DETAILS_GET = 'MANAGE_COMPETENCE_DETAILS_GET';
export const MANAGE_COMPETENCE_DETAILS_GET_REQUEST = 'MANAGE_COMPETENCE_DETAILS_GET_REQUEST';
export const MANAGE_COMPETENCE_DETAILS_GET_SUCCESS = 'MANAGE_COMPETENCE_DETAILS_GET_SUCCESS';
export const MANAGE_COMPETENCE_DETAILS_GET_FAILURE = 'MANAGE_COMPETENCE_DETAILS_GET_FAILURE';

export const fetchManageCompetenceDetails = payload => ({
  type: MANAGE_COMPETENCE_DETAILS_GET,
  payload,
});

export const fetchManageCompetenceDetailsRequest = payload => ({
  type: MANAGE_COMPETENCE_DETAILS_GET_REQUEST,
  payload,
});

export const fetchManageCompetenceDetailsSuccess = payload => ({
  type: MANAGE_COMPETENCE_DETAILS_GET_SUCCESS,
  payload,
});

export const fetchManageCompetenceDetailsFailure = payload => ({
  type: MANAGE_COMPETENCE_DETAILS_GET_FAILURE,
  payload,
});
