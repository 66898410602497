import React from 'react';
import {motion} from 'framer-motion';
import styled from '@emotion/styled';

const StyledBackdrop = styled(motion.div)`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    justify-items: center;
    display: flex;
    align-items: center;
    align-content: center;
    color: white;
`;

const animationProps = {
  initial: {opacity: 0},
  animate: {opacity: .7},
  exit: {opacity: 0},
  transition: {
    duration: .2,
    ease: 'easeIn',
  },
};

export const Backdrop = React.memo(({position = 'absolute', color}) => {
  const style = {
    position,
    backgroundColor: color || '#f9fbff',
  };

  return (
    <StyledBackdrop
      initial={animationProps.initial}
      animate={animationProps.animate}
      exit={animationProps.exit}
      transition={animationProps.transition}
      style={style}
    />
  );
});
