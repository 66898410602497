import {LoadingLine} from '@components/loading/loading-line';
import {loadableRetry} from '@utils/loadable-retry';

const LoadableLearningPathContainer = loadableRetry(() => {
  console.log('load learning.path');

  return import(/* webpackChunkName: "learning.path" */ './learning-path-container');
}, {
  fallback: <LoadingLine />,
  name: 'learning.path',
});

export default LoadableLearningPathContainer;
