export const USER_INIT = 'user/init';
export const USER_SUCCESS = 'user/success';
export const USER_LOADING = 'user/loading';
export const USER_ERROR = 'user/error';
export const USER_SET_PARTIAL = 'user/set-partial';

export const userInit = payload => ({
  type: USER_INIT,
  payload,
});
export const userSuccess = payload => ({
  type: USER_SUCCESS,
  payload,
});
export const userLoading = payload => ({
  type: USER_LOADING,
  payload,
});
export const userError = payload => ({
  type: USER_ERROR,
  payload,
});
export const userSetPartial = payload => ({
  type: USER_SET_PARTIAL,
  payload,
});
