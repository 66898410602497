import React, {useMemo} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {css, useTheme} from '@emotion/react';
import {Menu as SnapperMenu} from '@snapper/menu';
import './desktop-menu.scss';

const menuCss = css`
  min-height: 70px;
  justify-content: center !important;
  flex-basis: auto !important;
`;

const DesktopMenu = React.memo(({
  menuLinks,
  onLogoutClick,
  data,
  isMobile,
  history,
}) => {
  const theme = useTheme();

  const menuData = useMemo(() => ({
    ...data,
    isMobile,
  }), [data, isMobile]);

  return (
    <div className="desktop-menu">
      <SnapperMenu
        history={history}
        data={menuData}
        className="desktop-menu-wrapper"
        css={menuCss}
      />
      {!isMobile && menuLinks && (
        <div className="desktop-menu__bottom-section">
          {menuLinks.map(({name, path, active, exact = false, onMouseOver}, index) => (
            <NavLink
              className={classNames('desktop-menu__link', {'desktop-menu__link--active': active})}
              activeClassName="desktop-menu__link--active"
              exact={exact}
              data-testid={`menu-link-to-${path}`}
              style={{borderBottom: active ? `2px solid ${theme.colors.menu.header}` : '0'}}
              to={path}
              strict={false}
              key={path}
              onMouseOver={onMouseOver}
            >
              {name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
});

DesktopMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default DesktopMenu;
