import memoize from 'micro-memoize';
import {darken, lighten, rgba} from 'polished';
import {css} from '@emotion/react';

export const baseThemeColors = {
  dark: '#4D1980',
  primary: '#7025BB',
  light: '#B37FE6',
  surface: '#E6D4F7',
};

const baseThemeCourseCatalog = {
  articleCards: {subTitleColor: '#6B46C1'},

  coursegroups: {
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: '#4A1FB8',
      },
      inactive: {
        color: '#191927',
        backgroundColor: '#EBE9FE',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.025, '#EBE9FE'),
      },
    },
  },

  toggleSwitch: {
    active: {
      color: '#7352c7',
      backgroundColor: '#ededed',
    },
    inactive: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
  },
};

export const baseTheme = {
  success: '#028142',
  avatarRandomColorOptions: {luminosity: 'light'},
  avatarUseRandomColor: false,
  avatarBackgroundColor: '#ededed',
  backgroundColor: '#fff',
  backgroundHoverColor: '#ececec',
  borderHoverColor: '#712BC8',
  borderRadius: '5px',
  borderRadii: {
    button: '4px',
    carouselCard: '10px',
    'default': '5px',
    larger: '8px',
    quadruple: '20px',
    smaller: '4px',
    toast: '6px',
    treeView: {item: '4px'},
    triple: '15px',
  },
  boxBackgroundColor: '#EBEBF1',
  buttonBorderRadius: '4px',
  buttonPadding: '10px 14px',
  buttons: {
    primary: {
      color: '#ffffff',
      backgroundColor: '#1C1D1F',
      borderColor: lighten(0.1, '#1C1D1F'),
      backgroundColorHover: lighten(0.1, '#1C1D1F'),
      padding: '8px 16px',
      lineHeight: '1.5',
      borderRadius: '4px',
    },
    primaryTheme: {
      color: '#ffffff',
      backgroundColor: baseThemeColors.primary,
      backgroundColorHover: lighten(0.1, baseThemeColors.primary),
      borderColor: lighten(0.1, baseThemeColors.primary),
      padding: '8px 16px',
      lineHeight: '1.5',
      borderRadius: '4px',
    },
    error: {
      color: '#ffffff',
      backgroundColor: '#E00000',
      borderColor: darken(0.03, '#E00000'),
      borderWidth: 0,
      backgroundColorHover: darken(0.03, '#E00000'),
      padding: '8px 16px',
      lineHeight: '1.5',
      borderRadius: '4px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
    },
    danger: {
      color: '#ffffff',
      backgroundColor: '#E00000',
      backgroundColorHover: darken(0.03, '#E00000'),
      padding: '8px 16px',
      lineHeight: '1.5',
      borderRadius: '4px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
    },
    transparent: {
      padding: '1.1em 0.9em',
      borderRadius: '4px',
      backgroundColor: 'rgb(255, 255, 255, 0.2)',
      color: '#fff',
      backgroundColorHover: darken(0.2, 'rgba(255, 255, 255, 0.2)'),
      lineHeight: 1,
      fontSize: '0.9em',
    },
    transparentRound: {
      backgroundColor: 'rgb(255, 255, 255, 0.2)',
      color: '#fff',
      borderRadius: '4px',
      padding: '0.7em 2.2em',
      backgroundColorHover: darken(0.2, 'rgba(255, 255, 255, 0.2)'),
      lineHeight: 1,
    },
    link: {
      color: '#0b5dd7',
      colorHover: '#063376',
      margin: '0 0 1em 0',
      iconColor: '#0b5dd7',
      fontSize: '1em',
    },
    disabled: {
      color: '#fff',
      backgroundColor: '#B4C5CE',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      padding: '8px 16px',
      lineHeight: '1.5',
    },
    'default': {
      border: '1px solid #D0D5DD',
      borderRadius: '4px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
      backgroundColor: '#fff',
      color: '#344054',
      backgroundColorHover: darken(0.05, '#fff'),
      borderColor: '#D0D5DD',
      padding: '8px 16px',
      lineHeight: '1.5',
    },
    defaultTheme: {
      borderRadius: '4px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
      color: baseThemeColors.primary,
      border: '1px solid ' + baseThemeColors.primary,
      borderColor: 'green',
      padding: '6px 16px',
      lineHeight: '1.5',
    },
    cancel: {
      color: '#000',
      backgroundColor: 'transparent',
      backgroundColorHover: 'transparent',
      colorHover: lighten(0.1, '#000'),
      padding: '6px 16px',
      lineHeight: '1.5',
    },
    cancelDisabled: {
      color: 'rgba(0,0,0,0.3)',
      backgroundColor: 'transparent',
      backgroundColorHover: 'transparent',
      colorHover: 'rgba(0,0,0,0.3)',
      padding: '6px 16px',
      lineHeight: '1.5',
    },
    cancelTheme: {
      color: baseThemeColors.primary,
      backgroundColor: 'transparent',
      colorHover: darken(0.1, baseThemeColors.primary),
      padding: '6px 16px',
      lineHeight: '1.5',
    },
    oversize: {
      backgroundColor: '#7928ca',
      backgroundColorHover: darken(0.1, '#f05d00'),
      borderColor: 'transparent',
      borderColorHover: 'transparent',
      color: '#fff',
      borderRadius: '10px',
      padding: '30px 20px',
      margin: '0',
      colorHover: '#fff',
    },
    toggle: {
      backgroundColorSelected: '#7928ca',
      borderColorSelected: '#7928ca',
      colorSelected: '#fff',
      color: '#000',
      borderRadius: '10px',
      padding: '30px 20px',
      margin: '0',
    },
  },
  buttonSmallBorderRadius: '3px',
  buttonSmallPadding: '4px 8px',
  buttonTransition: 'background-color 0.1s ease-out, border-color 0.1s ease-out',
  cellBorderColor: '#EBEBF1',
  chartPrimaryColor: '#7928C9',
  chartSecondaryColor: '#CB66C2',
  chartBadge: '#CB66C2',
  chartSecondaryColorLight: '#CB66C2',
  color: '#14255C',
  colors: {
    primary: baseThemeColors.primary,
    surface: baseThemeColors.surface,
    borderColor: '#c2c2c2',
    danger: {
      border: '#ffd7d7',
      hover: '#a20000',
      main: '#e00000',
      pressed: '#630000',
      surface: '#ffecec',
    },
    error: {
      border: '#ffd7d7',
      hover: '#a20000',
      main: '#e00000',
      pressed: '#630000',
      surface: '#ffecec',
    },
    disabled: '#595959',
    gray: {
      '600': '#656565',
      '700': '#3b3b3b',
    },
    headerBorder: '#ced4da',
    icon: {
      color1: '#F4F3FF',
      color2: '#EBE9FE',
      ico: '#7F56D9',
    },
    info: {
      border: '#b6cff3',
      hover: '#044dba',
      main: '#0b5cd7',
      pressed: '#043c8f',
      surface: '#ecf2fc',
    },
    menu: {header: '#AA2FA5'},
    dropdown: {
      itemHoverColor: '#0B5CD7',
      itemHoverBackgroundColor: '#ECF2FC',
    },
    inputColor: '#424242',
    lightGray: '#efefef',
    navigationBorder: '#ccc',
    neutral: {
      '10': '#ffffff',
      '20': '#f5f5f5',
      '30': '#ededed',
      '35': '#e5e5e5',
      '40': '#e0e0e0',
      '50': '#c2c2c2',
      '60': '#9e9e9e',
      '70': '#757575',
      '80': '#616161',
      '90': '#424242',
      '100': '#0a0a0a',
    },
    persons: {header: '#495866'},
    reports: {
      selectReport: {
        colorSelected: '#0b5cd7',
        color: '#000',
      },
      progressBar: {backgroundColor: '#0B5CD7'},
    },
    success: {
      border: '#b3dac7',
      hover: '#006836',
      main: '#008243',
      pressed: '#005029',
      surface: '#ebf5f0',
    },
    warning: {
      border: '#ffca99',
      hover: '#d16603',
      main: '#ea780e',
      pressed: '#653508',
      surface: '#fff2e7',
    },
  },
  container: {maxWidth: '1280px'},
  fadedColor: '#C4C8CC',
  fonts: {
    heading: {
      small: '20px',
      medium: '28px',
      large: '36px',
    },
    text: {
      small: '12px',
      regular: '16px',
      large: '18px',
    },
    ss: {size: '0.7rem'},
    s: {size: '0.8rem'},
    sm: {size: '0.9rem'},
    m: {size: '1rem'},
    ml: {size: '1.1rem'},
    l: {size: '1.2rem'},
    xl: {size: '1.5rem'},
    xxl: {size: '2rem'},
    weights: {
      normal: 400,
      medium: 500,
      bold: 600,
    },
  },
  scheme: {
    primary: {
      color25: '#FAFAFF',
      color50: '#F4F3FF',
      color100: '#EBE9FE',
      color200: '#D9D6FE',
      color300: '#BDB4FE',
      color400: '#9B8AFB',
      color500: '#7A5AF8',
      color600: '#6938EF',
      color700: '#5925DC',
      color800: '#4A1FB8',
      color900: '#3E1C96',
    },
    gray: {
      color25: '#FCFCFD',
      color50: '#F9FAFB',
      color100: '#F2F4F7',
      color200: '#EAECF0',
      color300: '#D0D5DD',
      color400: '#98A2B3',
      color500: '#667085',
      color600: '#475467',
      color700: '#344054',
      color800: '#1D2939',
      color900: '#101828',
    },
    success: {
      color25: '#F5FAFF',
      color50: '#EFF8FF',
      color100: '#D1E9FF',
      color200: '#B2DDFF',
      color300: '#84CAFF',
      color400: '#53B1FD',
      color500: '#2E90FA',
      color600: '#1570EF',
      color700: '#175CD3',
      color800: '#1849A9',
      color900: '#194185',
    },
    error: {
      color25: '#FFFBFA',
      color50: '#FEF3F2',
      color100: '#FEE4E2',
      color200: '#FECDCA',
      color300: '#FDA29B',
      color400: '#F97066',
      color500: '#F04438',
      color600: '#D92D20',
      color700: '#B42318',
      color800: '#912018',
      color900: '#7A271A',
    },
    warning: {
      color25: '#F5FAFF',
      color50: '#EFF8FF',
      color100: '#D1E9FF',
      color200: '#B2DDFF',
      color300: '#84CAFF',
      color400: '#53B1FD',
      color500: '#2E90FA',
      color600: '#1570EF',
      color700: '#175CD3',
      color800: '#1849A9',
      color900: '#194185',
    },
  },
  progress: {
    big: {
      backgroundColor: '#7928ca',
      strokeWidth: 12,
    },
    'default': {
      backgroundColor: '#f500a9',
      strokeWidth: 12,
    },
  },
  tiles: {
    color1: '#7928ca',
    color2: '#7928ca',
  },
  getColor: memoize((
    property = 'color',
    type = 'neutral',
    variant = '90',
  ) => css`
    ${property}: ${baseTheme.colors[type][variant]};
  `),
  getFont: memoize((
    size,
    type = 'text',
    weight = 'normal',
  ) => css`
    font-weight: ${baseTheme.fonts.weights[weight] || weight};
    font-size: ${baseTheme.fonts[type][size] || size};
  `),
  getTransitions: memoize((
    properties,
    duration = 'duration',
    easingFunction = 'linear',
  ) => properties.map(property => `${property} ${baseTheme.transitions[duration]} ${baseTheme.transitions.easingFunctions.hasOwnProperty(easingFunction)
    ? baseTheme.transitions.easingFunctions[easingFunction]
    : easingFunction}`)
    .join(', ')),
  infoBackgroundColor: '#EDFBFF',
  inputBackgroundColor: '#fefefe',
  inputBackgroundColorFocus: '#fefefe',
  inputBorderColor: '#D9D9D9',
  inputBorderRadius: '5px',
  inputColor: '#111',
  inputDropdownBackgroundColor: '#fefefe',
  inputFontSize: '0.9em',
  inputIconColor: '#A8A8A8',
  inputLabelColor: '#949393',
  inputLabelFontSize: '0.9em',
  inputLabelFontWeight: 'normal',
  inputLabelLineHeight: '1.5',
  inputLabelMargin: '0',
  inputLineHeight: '1.5',
  inputPadding: '8px 8px',
  inputSmallBorderRadius: '5px',
  inputSmallFontSize: '0.9em',
  inputSmallLineHeight: '1',
  inputSmallPadding: '6px 6px',
  lightColor: '#fff',
  linkColor: '#14255C',
  linkColorHover: '#14255C',
  linkTextDecoration: 'none',
  linkTextDecorationHover: 'underline',
  listSeparatorColor: '#EBEBF1',
  modalBackdrop: rgba('#333', 0.75),
  modalBorder: '#c2c2c2',
  name: 'Snapper',
  oddBackgroundColor: '#FBFBFB',
  paddings: {input: '8px'},
  placeholderColor: '#8C8C8C',
  placeholderColorFocus: '#6a6868',
  primaryColor: '#14255C',
  resourceEditor: {
    secondaryBackground: '#fafafa',
    colorPicker: {
      blue: '#2e77eb',
      red: '#eb2e2e',
      orange: '#eb942e',
      lime: '#d1eb2e',
      green: '#a9d251',
      purple: '#aa2fa5',
      aqua: '#2eebc9',
    },
    imagePicker: '#e0e0e0',
    imageRequirements: '#0b5cd7',
  },

  transitions: {
    duration: '0.5s',
    fastDuration: '0.25s',
    durations: {
      'default': '0.5s',
      fast: '0.25s',
    },
    easingFunctions: {
      easeInOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
      easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    },
  },
  treeView: {
    colors: {
      normal: {
        activeGroup: '#0a0a0a',
        activeItem: '#000',
        'default': '#000',
        group: '#595959',
        hover: rgba('#f2f2f2', 0.95),
        lineGray: '#ededed',
      },
      dark: {
        activeGroup: '#0a0a0a',
        activeItem: '#fff',
        'default': '#fff',
        group: '#595959',
        hover: rgba(223, 223, 223, 0.19),
        lineGray: '#ededed',
      },
    },
    fontWeights: {
      activeItem: 500,
      'default': 400,
    },
  },
  settings: {
    colors: {
      bgTitle: '#f6f8fA',
      border: '#e1e4e8',
    },
  },
  carousel: {
    colors: {
      textGrey: '#6f7172',
      cardBg: '#f9f9fb',
      hover: '#fafafa',
    },
    borders: {
      newItem: '2px dashed #e0e0e4;',
      card: '1px solid #e0e0e4;',
    },
  },
  utility: {
    visuallyHidden: css`
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;`,
  },
  courseCatalog: baseThemeCourseCatalog,
};
