import React from 'react';
import {createPortal} from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import {notificationsHide} from '@actions/notifications.actions';
import {getShownNotifications} from '@selectors/notifications.selectors';
import Notification from './notification';
import './notifications.scss';

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getShownNotifications);

  if (!notifications.length) return null;

  return createPortal(
    <div className="notifications">
      <div className="notifications__wrapper">
        {notifications.map(notification => (
          <Notification
            title={notification.title}
            color={notification.color}
            onClose={() => dispatch(notificationsHide({notification}))}
            key={`notification-${notification.id}`}
            redirectTo={notification.redirectTo}
          >
            {notification.text}
          </Notification>
        ))}
      </div>
    </div>,
    document.querySelector('#notifications'),
  );
};

export default Notifications;
