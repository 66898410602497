export const defaultCourseCatalogLayout = {
  sections: [
    {
      id: 'top',
      variant: 'default',
      widgets: [
        {
          id: 'featuredArticles',
          variant: 'single',
        },
        {id: 'title'},
        {id: 'coursegroups'},
      ],
    },
    {
      id: 'sidepanel',
      widgets: [
        {id: 'searchbar'},
        {id: 'subCategories'},
      ],
    },
    {
      id: 'mainTop',
      widgets: [
        {id: 'toggleUpcomingEvents'},
      ],
    },
    {
      id: 'main',
      widgets: [
        {
          id: 'catalog',
          options: {
            gridColumns: 3,
            pageSize: 9,
            cardVariant: 'default',
            enableUpcomingEventsView: true,
            enableCompetencesView: true,
          },
        },
      ],
    },
  ],
};

export const initLayoutConfig = routeLayoutConfig => {
  const {
    sections: layoutSections,
    options: layoutOptions,
  } = routeLayoutConfig || {};

  if (!layoutSections || typeof layoutSections !== 'object') {
    console.error('Layout config not found');

    return null;
  };

  const config = Object.fromEntries(layoutSections.map(({id, widgets, variant, options}) => [id, {
    id,
    widgets,
    variant,
    options,
  }]));

  return {
    ...config,
    options: layoutOptions,
  };
};
