import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import FlagNo from '@assets/svg/flags/flag_no.svg';
import FlagUS from '@assets/svg/flags/flag_us.svg';
import {css} from '@emotion/react';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import {useOnClickOutside} from '@src/hooks/hooks';
import {i18n} from '@src/i18n';
import {screenreaderOnlyMinxin} from '@styles/mixins';
import {
  ChooseLangHeader,
  ChooserWrapper,
  CloseBtn,
  Help,
  LangFlagWrapper,
  LangName,
  Language,
  LanguageList,
  OpenListItemsWrapper,
  PopupWrapper,
  SelectStatusDot,
  SelectStatusDotFill,
  Separator,
} from './styles';

const languageMap = {
  no: {
    longname: 'Norsk',
    flag: FlagNo,
  },
  en: {
    longname: 'English',
    flag: FlagUS,
  },
};

const LanguageHelp = () => (
  <Help>
    <p>Can’t find your language?</p>
    <p>
      Take a look on our
      {' '}
      <a>FAQ</a>
      {' '}
      for more
    </p>
  </Help>
);

const LanguageSelect = ({languages, currLanguage = 'no', onChoosedLanguage}) => {
  const [listIsOpen, setListIsOpen] = useState(false);
  const [openedByMouse, setOpenedByMouse] = useState(false);
  const langSelectBtnRef = useRef();

  const popupRef = useRef();

  useOnClickOutside(popupRef, () => setListIsOpen(false));

  const onKeyUp = evt => {
    if (evt.key === 'Escape') {
      setListIsOpen(false);
    }
  };

  // if the language select was opened using mouse, remove the focus from
  // the opening button to prevent focus styles
  const onKeyDown = () => {
    setOpenedByMouse(state => {
      if (state) {
        langSelectBtnRef.current.blur();
      }

      return state;
    });
  };

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
      document.addEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <>
      <ChooserWrapper
        aria-hidden="true"
      >
        <PopupWrapper
          listIsOpen={listIsOpen}
          ref={popupRef}
          className="lang-select-wrapper"
        >
          <Language
            ref={langSelectBtnRef}
            tabIndex="0"
            role="button"
            data-testid="open-lang-select"
            css={css`
              margin: 0;
              margin-bottom: 0.5em;
            `}
            onClick={() => {
              setListIsOpen(old => !old);
              setOpenedByMouse(true);
            }}
            onKeyUp={evt => {
              if (evt.key === 'Enter' || evt.key === 'Space') {
                setListIsOpen(old => !old);
                setOpenedByMouse(false);
              }
            }}
          >
            <LangFlagWrapper>
              <img
                src={languageMap[currLanguage]?.flag}
                alt=""
              />
            </LangFlagWrapper>
            {languageMap[currLanguage]?.longname}
          </Language>

          <CloseBtn
            listIsOpen={listIsOpen}
            onClick={() => setListIsOpen(false)}
            onKeyUp={evt => {
              if (evt.key === 'Enter' || evt.key === 'Space') {
                setListIsOpen(false);
              }
            }}
            tabIndex="0"
            role="button"
            icon={faTimes}
          />
          {listIsOpen && (
            <OpenListItemsWrapper>
              <Separator variant="large" />

              <ChooseLangHeader>{i18n('login.select-language')}</ChooseLangHeader>
              <LanguageList data-testid="languages-list">
                {languages.map((langcode, idx, arr) => (
                  <>
                    <Language
                      key={langcode}
                      data-testid="language"
                      onClick={() => {
                        setListIsOpen(false);
                        onChoosedLanguage(langcode);
                      }}
                      onKeyUp={evt => {
                        if (evt.key === 'Enter' || evt.key === 'Space') {
                          setListIsOpen(false);
                          onChoosedLanguage(langcode);
                          if (langSelectBtnRef.current) {
                            langSelectBtnRef.current.focus();
                          }
                        }
                      }}
                      tabIndex="0"
                      role="button"
                      css={
                        idx + 1 === arr.length
                          ? css`
                              margin-bottom: 1.4em;
                            `
                          : ''
                      }
                    >
                      <LangFlagWrapper>
                        <img
                          src={languageMap[langcode]?.flag}
                          alt=""
                        />
                      </LangFlagWrapper>
                      <LangName>{languageMap[langcode].longname}</LangName>
                      <SelectStatusDot isActive={currLanguage === langcode}>
                        <SelectStatusDotFill />
                      </SelectStatusDot>
                    </Language>
                    {idx + 1 !== arr.length && <Separator variant="small" />}
                  </>
                ))}
              </LanguageList>
            </OpenListItemsWrapper>
          )}
        </PopupWrapper>
      </ChooserWrapper>

      {/* Custom selects are tricky to get right for screenreaders, use the good old native select-element instead */}
      <div css={screenreaderOnlyMinxin}>
        <select
          tabIndex="-1"
          defaultValue={localStorage.getItem('language') || 'no'}
          onBlur={evt => onChoosedLanguage(evt.target.value)}
        >
          {languages.map(langcode => (
            <option
              value={langcode}
              key={langcode}
            >
              {languageMap[langcode]['longname']}
            </option>
          ))}
        </select>
        <LanguageHelp />
      </div>
    </>
  );
};

LanguageSelect.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string),
  currLanguage: PropTypes.string,
  onChoosedLanguage: PropTypes.func,
};

export default LanguageSelect;
