import * as configActions from '@actions/config.actions';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import * as T from '@types/load.types';

const initialState = {
  isBackgroundUpdating: false,
  isFetching: false,
  data: null,
  error: null,
  status: T.LoadStatuses.NOT_LOADED,
  __meta: null,
};

const config = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case configActions.CONFIG_GET_CONFIG_REQUEST: {
    const {backgroundUpdate, __meta} = action?.payload || {};

    if (backgroundUpdate) {
      return {
        ...state,
        isBackgroundUpdating: true,
        __meta: __meta || state.__meta,
      };
    }

    return {
      ...state,
      isFetching: true,
      status: T.LoadStatuses.IS_LOADING,
      isBackgroundUpdating: false,
      __meta: __meta || state.__meta,
    };
  }
  case configActions.CONFIG_GET_CONFIG_SUCCESS: {
    if (!action.payload?.config) return state;

    return {
      ...state,
      isBackgroundUpdating: false,
      data: action.payload.config,
      status: T.LoadStatuses.LOADED,
      __meta: action.payload.__meta || state.__meta,
    };
  }
  case configActions.CONFIG_GET_CONFIG_FAILURE: {
    return {
      ...initialState,
      error: action.payload.error,
      status: T.LoadStatuses.FAILED,
      __meta: action.payload.__meta || state.__meta,
    };
  }
  default: {
    return state;
  }
  }
};

export default config;
