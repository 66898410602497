import React from 'react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {faRobot} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const IconWrapper = styled.div`
  float: left;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.menuIconColor || theme.scheme.primary.color700};
  background: ${({theme}) => theme.menuIconBackgroundColor || theme.scheme.primary.color100};
  outline: .5rem white;
`;

const AIMenuItem = React.memo(() => (
  <div
    css={css({
      marginLeft: '-55px',
      display: 'inline-block',
      '&:hover .title': {textDecoration: 'underline'},
    })}
  >
    <IconWrapper>
      <FontAwesomeIcon icon={faRobot} />
    </IconWrapper>
    <span className="title">AI Content Creator</span>
    <span
      css={css`
        display: block;
        color: #667085;
        margin-top: 0.5em;
        font-size: 14px;
      `}
    >
      Lag kurs med AI
    </span>
  </div>
));

export const injectAICourseBuilderMenuItem = (
  managerSubmenuItems,
  {
    onClick,
    onMouseOver,
  },
) => {
  const targetItems = managerSubmenuItems?.find?.(item => item?.label === 'Opprett og endre')?.items;

  if (!targetItems?.length) return;

  const [firstColumn] = targetItems;

  if (!Array.isArray(firstColumn)) return;

  firstColumn.push({
    onClick,
    onMouseOver,
    type: 'featureLink',
    icon: ['fas', 'microchip-ai'],
    label: <AIMenuItem />,
  });
};
